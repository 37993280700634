import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SharedInitializationService } from '../../shared/shared-common-initialization.service';
import { DatePipe } from '@angular/common';
import { WorkflowNameService } from '../../workflow/services/workflow-name.service';
import { CustomHttpParamEncoder } from '../../shared/custom-http-param-encoder';
import { SharedGeneralFunctionsService } from '../../shared/shared-general-functions.service';
import { AnalysisService } from './analysis.service';

@Injectable()
export class AnalysisLabelSelectedService {
  constructor(private appService: AppService,
    private httpClient: HttpClient,
    private analysisService: AnalysisService,
    private workflowNameService: WorkflowNameService,
    private sharedService: SharedInitializationService,
    private sharedGeneralService: SharedGeneralFunctionsService) {
  }

  webUrl = this.appService.getWebUrl();

  // this is clicked in the menu and to see whether the labelise selected part should be displayed in grid
  labelSelectedChange: { [index: string]: Subject<any> } = {};
  labelSelected: {} = {}
  disableLabelSelectedButtonChange: { [index: string]: Subject<boolean> } = {};
  labelSelectedDetails: {} = {};
  labelSelectedDetailsChange: { [index: string]: Subject<boolean> } = {};
  disableLabelSelectedDetailsButtonChange: { [index: string]: Subject<boolean> } = {};

  // this is called in analysis grid component and automatically updates the true status change so that the analysis load tables is called again
  updateLabelTrueStatusChange: { [index: string]: Subject<boolean> } = {};

  enableLabelSelectedDetailsButtonChange: { [index: string]: Subject<boolean> } = {};

  updateLabelDetailsTrueStatusChange: { [index: string]: Subject<boolean> } = {};

  gridData: any = {};

  //init analysis tab changes
  initTabChanges(tabs) {
    for (var i in tabs) {
      this.labelSelected[tabs[i].name] = false;
      this.labelSelectedChange[tabs[i].name] = new Subject<any>();
      this.labelSelectedDetails[tabs[i].name] = false;
      this.labelSelectedDetailsChange[tabs[i].name] = new Subject<any>();
      this.disableLabelSelectedButtonChange[tabs[i].name] = new Subject<boolean>();
      this.updateLabelTrueStatusChange[tabs[i].name] = new Subject<boolean>();
      this.disableLabelSelectedDetailsButtonChange[tabs[i].name] = new Subject<boolean>();
      this.enableLabelSelectedDetailsButtonChange[tabs[i].name] = new Subject<boolean>();
      this.labelSelectedDetailsChange[tabs[i].name] = new Subject<boolean>();
      this.updateLabelDetailsTrueStatusChange[tabs[i].name] = new Subject<boolean>();
    }

  }

  //Allows to upload and save files in the server for a specific comment
  //called in analysis audit component
  uploadTrueStatusFile(files, workflow, transactionID, pasteFiles) {

    const url = this.webUrl;
    const webservice = "AnalysisUploadTrueStatusFile";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeadersUpload();

    pasteFiles = JSON.stringify(pasteFiles);
    const formData = new FormData();

    transactionID = transactionID.replace(/:/g, '');
    for (var i = 0; i < files.length; i++) {
      let fileToUpload = <File>files[i];
      formData.append('file', fileToUpload, fileToUpload.name);
    }

    formData.append('transactionID', transactionID)
    formData.append('wfName', workflow)
    formData.append('pasteFiles', pasteFiles)
    formData.append('enctype', 'multipart/form-data');
    this.appService.startSpin();

    return this.httpClient.post(completeUrl, formData, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage('Success', response.statusText);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error);
        this.appService.stopSpin();
      })
  }

  //Changes the status of the record in the page according to the user input.
  //called in analysis audit component
  //updateTrueStatus(workflow, source, segAttribute, table, predictedFile,fieldID,listOfId) {
  updateTrueStatus(workflow, table, type, predictedFile, fieldID, listOfId, files, initFiles, fieldIDValue, tab, details, predictionMode, from, fieldIDSelected, listOfSelected, pasteFiles) {

    const url = this.webUrl;

    // 
    // fieldID is the field ID of the workflow or
    // when it's not detail
    // for aggregate workflow  "CycleID" or "aggregationID"
    // for temporal workflow "leaf_name"
    // or cardabel_rec for tab matching

    const webservice = "AnalysisUpdateTrueStatusForPage";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', table)
      .set('predictedFile', predictedFile)
      .set('fieldID', fieldID)
      .set('listOfId', listOfId)
      .set('files', files)
      .set('predictionMode', predictionMode)
      .set('fieldIDSelected', fieldIDSelected)
      .set('listOfSelected', listOfSelected);

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          if ((initFiles) && (pasteFiles)) {
            if ((initFiles.length != 0) || (pasteFiles.length != 0)) {
              this.uploadTrueStatusFile(initFiles, workflow, fieldIDValue, pasteFiles)
            }
          }
          else {
            this.appService.showMessage('Success', response.statusText);
          }
          listOfId = JSON.parse(listOfId);
          listOfId = listOfId.listOfId;
          let gridData = "gridData";
          let gridDataChange = "gridDataChange";
          if (details) {
            gridData = "gridDataDetails";
            gridDataChange = "gridDataDetailsChange";
          }

          let service = from + "Service";//'analysis' or 'matching'
          for (var i in this[service][gridData][tab]) {
            if (this[service][gridData][tab][i][this.analysisService.fieldID_audit[tab]] === listOfId[0][this.analysisService.fieldID_audit[tab]]) {
              this[service][gridData][tab][i]["TrueStatus"] = listOfId[0]["TrueStatus"];
              this[service][gridData][tab][i]["TrueStatusType"] = listOfId[0]["TrueStatusType"];
              let segattr = this.workflowNameService.getSegregationAttribute();
              let type = this.workflowNameService.getType();

              if (this[service][gridData][tab][i]["TrueStatusWorkflow"] != undefined) {
                if ((predictedFile != '') && (this.sharedGeneralService.isWithSubtables(type))) {
                  this[service][gridData][tab][i]["TrueStatusWorkflow"] = workflow + '@' + segattr + '_' + table + '@' + predictedFile;
                }
                else {
                  this[service][gridData][tab][i]["TrueStatusWorkflow"] = workflow + '@' + segattr + '_' + table;
                }
              }
              if (from === "analysis") {
                break;
              }
            }
            else {
              if (this[service][gridData][tab][i]["TrueStatus"] === undefined) {
                this[service][gridData][tab][i]["TrueStatus"] = "";
                this[service][gridData][tab][i]["TrueStatusType"] = "";
                if (this[service][gridData][tab][i]["TrueStatusWorkflow"] != undefined) {
                  this[service][gridData][tab][i]["TrueStatusWorkflow"] = "";
                }
              }
            }
          }

          this[service][gridDataChange][tab].next(this[service][gridData][tab]);
          // both detail and original must be updated 
          if ((from == "analysis") && (type == "Matching")) {
            gridData = "gridDataDetails";
            gridDataChange = "gridDataDetailsChange";
            if (this[service][gridData][tab] != undefined) {
              for (var i in this[service][gridData][tab]) {
                if (this[service][gridData][tab][i][this.analysisService.fieldID_audit[tab]] === listOfId[0][this.analysisService.fieldID_audit[tab]]) {
                  this[service][gridData][tab][i]["TrueStatus"] = listOfId[0]["TrueStatus"];
                  this[service][gridData][tab][i]["TrueStatusType"] = listOfId[0]["TrueStatusType"];
                  let segattr = this.workflowNameService.getSegregationAttribute();
                  let type = this.workflowNameService.getType();

                  if (this[service][gridData][tab][i]["TrueStatusWorkflow"] != undefined) {
                    if ((predictedFile != '') && (this.sharedGeneralService.isWithSubtables(type))) {
                      this[service][gridData][tab][i]["TrueStatusWorkflow"] = workflow + '@' + segattr + '_' + table + '@' + predictedFile;
                    }
                    else {
                      this[service][gridData][tab][i]["TrueStatusWorkflow"] = workflow + '@' + segattr + '_' + table;
                    }
                  }
                  if (from === "analysis") {
                    break;
                  }
                }
                else {
                  if (this[service][gridData][tab][i]["TrueStatus"] === undefined) {
                    this[service][gridData][tab][i]["TrueStatus"] = "";
                    this[service][gridData][tab][i]["TrueStatusType"] = "";
                    if (this[service][gridData][tab][i]["TrueStatusWorkflow"] != undefined) {
                      this[service][gridData][tab][i]["TrueStatusWorkflow"] = "";
                    }
                  }
                }
              }
              this[service][gridDataChange][tab].next(this[service][gridData][tab]);
            }
          }

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      })
  }

  //Changes the status of records in the page according to the user input.
  //called in analysis labelise selected component
  updateTrueStatusRulesForRows(workflow, segAttribute, table, predictedFile, fieldID, ruleComment, ruleTrueStatus, ruleTrueStatusType, ruleAssignFrom, ruleAssignTo, selected, fromDetails, predictionMode, ruleCost, tab) {

    const url = this.webUrl;
    const webservice = "AnalysisUpdateTrueStatusForSelected";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    var today = new Date();
    let ruleTime = this.sharedService.returnFullTime(today);
    var datePipe = new DatePipe('en-US');
    let ruleDate = datePipe.transform(today, 'yyyy/MM/dd');

    ruleDate = ruleDate.substr(0, 4) + ruleDate.substr(5, 2) + ruleDate.substr(8);
    ruleTime = ruleTime.substr(0, 2) + ruleTime.substr(3, 2) + ruleTime.substr(6);

    let tabRule = [];

    // fieldID is the field ID of the workflow or
    // when it's not detail
    // for aggregate workflow  "CycleID" or "aggregationID"
    // for temporal workflow "leaf_name"
    if (!fromDetails) {
      fieldID = this.analysisService.getFieldID_audit(tab);
    }

    for (var i in selected) {
      let newdata = {
        predictionNature: ""
      }

      newdata[fieldID] = selected[i][fieldID]
      if (selected[i].typeOfAnomaly !== undefined)
        newdata.predictionNature = selected[i].typeOfAnomaly;
      else
        newdata.predictionNature = "";

      tabRule.push(newdata);
    }

    let listSelected = JSON.stringify(tabRule);
    listSelected = "{\"listOfSelected\":" + listSelected + "}";

    let cost = JSON.stringify(ruleCost);
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      //  .set('segAttr', segAttribute)
      .set('segAttrValue', table)
      .set('predictedFile', predictedFile)
      .set('RuleComment', ruleComment)
      .set('RuleTrueStatus', ruleTrueStatus)
      .set('RuleTrueStatusType', ruleTrueStatusType)
      .set('RuleAssignFrom', ruleAssignFrom)
      .set('RuleAssignTo', ruleAssignTo)
      .set('RuleDate', ruleDate)
      .set('RuleTime', ruleTime)
      .set('ListSelected', listSelected)
      .set('predictionMode', predictionMode)
      .set('fieldID', fieldID)
      .set('RuleCost', cost)


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {

          this.appService.showMessage("Success", response.statusText);
          if (fromDetails) {
            this.updateLabelDetailsTrueStatusChange[tab].next(true);
          }
          else {
            this.updateLabelTrueStatusChange[tab].next(true);
          }
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      })
  }

  //Changes the status of records in the page according to the user input.
  //called in analysis labelise selected component
  updateTrueStatusRulesForLabel(workflow, segAttribute, table, selectedCombination, attributes, predictedFile, fieldID, ruleComment, ruleTrueStatus, ruleTrueStatusType, ruleAssignFrom, ruleAssignTo, predictionMode, tab) {

    const url = this.webUrl;
    const webservice = "AnalysisUpdateTrueStatusForLabel";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();

    this.appService.startSpin();

    var today = new Date();
    let ruleTime = this.sharedService.returnFullTime(today);
    var datePipe = new DatePipe('en-US');
    let ruleDate = datePipe.transform(today, 'yyyy/MM/dd');

    ruleDate = ruleDate.substr(0, 4) + ruleDate.substr(5, 2) + ruleDate.substr(8);
    ruleTime = ruleTime.substr(0, 2) + ruleTime.substr(3, 2) + ruleTime.substr(6);

    var ruleAttributes = "{\"ListOfLabels\":" + attributes + "}";
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      //.set('segAttr', segAttribute)
      .set('segAttrValue', table)
      .set('predictedFile', predictedFile)
      .set('RuleComment', ruleComment)
      .set('Attributes', ruleAttributes)
      .set('RuleTrueStatus', ruleTrueStatus)
      .set('RuleTrueStatusType', ruleTrueStatusType)
      .set('RuleAssignFrom', ruleAssignFrom)
      .set('RuleAssignTo', ruleAssignTo)
      .set('RuleDate', ruleDate)
      .set('RuleTime', ruleTime)
      .set('predictionMode', predictionMode)


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);
          this.updateLabelTrueStatusChange[tab].next(true)
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      })
  }

  //creates a new rule in the Bayesian workflow
  //called in analysis labelise selected component
  //saveNewRule(workflow, segAttribute, table, ruleName, ruleComment, selectedCombination, attributes, ruleTrueStatus, ruleTrueStatusType, ruleAssignFrom, ruleAssignTo) {
  saveNewRule(workflow, segAttribute, table, ruleName, ruleComment, ruleAttributes, ruleTrueStatus, ruleTrueStatusType, ruleAssignFrom, ruleAssignTo, tab) {

    const url = this.webUrl;
    const webservice = "AnalysisSaveRule";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();
    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      //.set('segAttr', segAttribute)
      .set('segAttrValue', table)
      .set('RuleName', ruleName)
      .set('RuleComment', ruleComment)
      .set('Attributes', ruleAttributes)
      .set('RuleTrueStatus', ruleTrueStatus)
      .set('RuleTrueStatusType', ruleTrueStatusType)
      .set('RuleAssignFrom', ruleAssignFrom)
      .set('RuleAssignTo', ruleAssignTo)


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);
          this.updateLabelTrueStatusChange[tab].next(true);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      })
  }

  //save a new combination to be ignored in complex detection in the Bayesian workflow
  //called in analysis labelise selected component
  saveNewCombination(workflow, table, ruleAttributes, tab) {

    const url = this.webUrl;
    const webservice = "AnalysisSaveCombination";
    const completeUrl = url + webservice;

    const headers = this.appService.getHeaders();
    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', table)
      .set('Attributes', ruleAttributes)

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage("Success", response.statusText);
          this.workflowNameService.updateWorkflowTableSubTable(table, response.wfStructure, "");

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      })
  }

  //determines whether the labelise selected area should be shown or not
  //called in analysis menu and analysis labelise selected component
  showLabelSelected(param, tab, details) {

    let labelSelected = "labelSelected";
    let labelSelectedChange = "labelSelectedChange";
    if (details) {
      labelSelected = "labelSelectedDetails";
      labelSelectedChange = "labelSelectedDetailsChange";
    }
    this[labelSelected][tab] = param;
    this[labelSelectedChange][tab].next(this[labelSelected][tab]);

  }


  //determines whether the Labelise Selected button within analysis menu should be disabled or not
  //called in analysis menu
  disableLabelSelectedButton(param, tab, details) {
    let disableLabelSelectedButtonChange = "disableLabelSelectedButtonChange";
    let disableMainLabelSelectedButtonChange = "disableLabelSelectedDetailsButtonChange";
    if (details) {
      disableLabelSelectedButtonChange = "disableLabelSelectedDetailsButtonChange";
      disableMainLabelSelectedButtonChange = "disableLabelSelectedButtonChange";
    }
    this[disableMainLabelSelectedButtonChange][tab].next(!param);
    this[disableLabelSelectedButtonChange][tab].next(param);

    if (param === true) {
      this.showLabelSelected(false, tab, details);
    }

  }


}
