import { Injectable, isDevMode } from '@angular/core';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { AuthService } from './auth/auth-service';
import { Subject } from 'rxjs';
import { ConfigurationLoader } from "./configuration/configuration-loader.service";
import { SharedInitializationService } from './shared/shared-common-initialization.service';


@Injectable()
export class AppService {
  static showMessage(arg0: string, arg1: string) {
    throw new Error('Method not implemented.');
  }


  // url = "http://localhost:80/FraudDetect/WSDataWorkflow.asmx/";
  // constructor(private messageService: MessageService){
  //   }

  constructor(private messageService: MessageService,
    configurationLoader: ConfigurationLoader) {
    if (isDevMode()) {
      this.url = configurationLoader.getConfiguration().cardabelUrl;
      this.appUrl = configurationLoader.getConfiguration().appNavigationUrl;
      this.webUrl = configurationLoader.getConfiguration().webUrl;
      this.scatterUrl = configurationLoader.getConfiguration().scatterUrl;
      this.graphUrl = configurationLoader.getConfiguration().graphUrl;
    } else {
      this.appUrl = window.location.origin + '/';
      this.webUrl = window.location.origin + '/api/';
      this.url = this.webUrl;
      this.scatterUrl = window.location.origin + '/cardabeldash/';
      this.graphUrl = window.location.origin + '/cardabeldash/';
    }

    console.log(window.location.origin);
    console.log(this.url);
    console.log(window.location);
  }



  getUrl() {
    return this.url;
  }

  getAppUrl() {
    return this.appUrl;
  }

  getWebUrl() {
    return this.webUrl;
  }

  getScatterUrl() {
    return this.scatterUrl;
  }

  getGraphUrl() {
    return this.graphUrl;
  }

  setToken(token) {
    this.token = token;
  }

  getToken() {
    return this.token;
  }

  getHeaders() {
    return new HttpHeaders(
      {
        'Content-Type': 'application/x-www-form-urlencoded'
       /* 'Authorization': `Bearer ${this.token}` */
      })
  }

  getHeadersUpload() {
    return new HttpHeaders(
      {
        //  'Content-Type': 'application/x-www-form-urlencoded',
        //'Authorization': `Bearer ${this.token}`
      })
  }


  workflow: string = "";
  table: string = "";
  subtable: string = "";
  type: string = "";
  source: string = "";
  segAttribute: string = "";
  dynSegAttribute: string = "";
  url: String;
  appUrl: String;
  webUrl: String;
  scatterUrl: any;
  graphUrl: any;
  topic: string = "ALL";
  token: string = "qwer";
  selectedTopicChange = new Subject<any>();

  theme: string = "theme-dark";
  themeChange = new Subject<string>();
  // try
  previousMessage = "";
  //
  filtersClearedChange = new Subject<any>();

  //to close navigation windows
  navigationWindows: { url: string, name: string }[] = [];
  navigationWindowsChange = new Subject<any>();
  loadRecordNumber: number = 10000;
  auditFiles: boolean = false;

  displayContextMenuChange = new Subject<any>();

  startSpin() {
    var spinId = document.getElementById('spinningContainer');
    //  console.log(spinId);
    if (spinId != null) {
      spinId.classList.add('spinningClass');
    }

  }

  stopSpin() {
    var spinId = document.getElementById('spinningContainer');
    // console.log(spinId);
    if (spinId != null) {
      spinId.classList.remove('spinningClass');
    }

  }
  startSpin2() {
    var spinId = document.getElementById('spinningContainer');
    //  console.log(spinId);
    if (spinId != null) {
      spinId.classList.add('spinningClass2');
    }

  }


  stopSpin2() {
    var spinId = document.getElementById('spinningContainer');
    // console.log(spinId);
    if (spinId != null) {
      spinId.classList.remove('spinningClass2');
    }

  }
  showMessage(type, message) {
    console.log("this.previousMessage");
    console.log("type");
    console.log("message")

    if (type === 'Error') {
      //  if (message!==this.previousMessage){
      this.messageService.add({ severity: 'error', sticky: true, summary: 'Error Message', detail: message })
      //  }
    }
    else if (type === 'Success') {
      this.messageService.add({ severity: 'success', summary: 'Success Message', detail: message, life: 3000 })
    }
    else if (type === "Info") {
      this.messageService.add({ severity: 'info', summary: 'Info Message', detail: message })
    }
    else if (type === "Warning") {
      this.messageService.add({ severity: 'warn', sticky: true, summary: 'Warning Message', detail: message })
    }

    this.previousMessage = message;
  }

  setWorkflow(workflow) {
    this.workflow = workflow;
  }

  getWorkflow() {
    return this.workflow;
  }

  setTable(table) {
    this.table = table;
  }

  setUserTable(table) {
    this.table = table;
  }

  getTable() {
    return this.table;
  }

  setSubtable(subtable) {
    console.log("ssssssssssssssssetSubtable");
    this.subtable = subtable;
  }

  setUserSubtable(subtable) {
    console.log("ssssssssssssssssetUserSubtable");
    this.subtable = subtable;
  }


  getSubtable() {
    return this.subtable;
  }

  setSource(source) {
    this.source = source;
  }

  getSource() {
    return this.source;
  }

  setType(type) {
    this.type = type;
  }

  getType() {
    return this.type;
  }

  setSegAttribute(segAttribute) {
    this.segAttribute = segAttribute;
  }

  getSegAttribute() {
    return this.segAttribute;
  }

  setDynSegAttribute(dynSegAttribute) {
  this.dynSegAttribute = dynSegAttribute;
  }
  getDynSegAttribute() {
    return this.dynSegAttribute
  }

  setTheme(theme) {
    this.theme = theme;
    this.themeChange.next(this.theme)

  }

  getTheme() {
    return this.theme;
  }

  setLoadRecordNumber(number) {
    this.loadRecordNumber = number;
  }

  getLoadRecordNumber() {
    return this.loadRecordNumber;
  }

  setAuditFiles(param) {
    this.auditFiles = param;
  }

  getAuditFiles() {
    return this.auditFiles;
  }

  setSelectedTopic(topic, from) {
    this.topic = topic;
    let data = {
      topic: this.topic,
      from: from
    }
    this.selectedTopicChange.next(data);
  }

  getSelectedTopic() {
    return this.topic;
  }

  clearFilters() {
    this.filtersClearedChange.next(true);
  }


  //this allows to return the navigation windows
  returnNavigationWindows() {
    return this.navigationWindows;
  }

  //display context menu

  showContextMenu(event) {
    this.displayContextMenuChange.next(event);
  }

  truestatus_normal: string = "#41BB8A";
  truestatus_anomaly: string = "#F55B5B";
  truestatus_followup: string = "#B0C8DA";
  truestatus_assign: string = "7D9EB8";

  //bayes
  anomaly_simple: string = "lightsalmon";
  anomaly_combined: string = "indianred";
  anomaly_rule: string = "#0daaba";

  //gauss
  anomaly_gauss: string = "lightsalmon";

  //kernel
  anomaly_missing: string = "lightsalmon";
  anomaly_duplicates: string = "indianred";
  anomaly_all: string = "#F55B5B";

  //supervised
  anomaly_supervised: string = "lightsalmon";

  analysis_global_chart_data = "indianred";
  analysis_page_chart_data = "lightgrey";


  cardabel_chart_color_scheme = ['#0daaba', '#0C6D98', '#0788AF', '#16A5C2']
  gauss_scatter_plot_color_scheme = [{ 's': 'yellow', 'k': 'lightgreen', 'sk': 'brown', 'g': 'orange', 'gs': 'blue', 'gk': 'green', 'gsk': 'red', 'n': 'black' }];
  gauss_scatter_plot_method_scheme =  [{ 's': 'SVM', 'k': 'KNN', 'g': 'GM', 'n': 'Normal' }];
  gauss_supervised_first_dimension: string = "orange";
  gauss_supervised_second_dimension: string = "red";
  gauss_supervised_third_dimension: string = "green";
  gauss_3D_plot_color_scheme: string[] = ['steelblue', 'red', 'green'];
  cardabel_temporal_leaves_color_scheme = ['blue', 'green', 'red']

  themes: any[] = []

  set_color(param:string, value: any) {
    this[param] = value;
  }


  set_new_theme_properties(property: string, value: string) {
    var r = document.querySelector(':root.'+this.theme);
    r['style'].setProperty('---'+property, value);
  }


  handleError(error: HttpErrorResponse) {
    //  this.stopSpin();
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      return throwError(
        error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      return throwError(
        error.message);
    }
    // return an observable with a user-facing error message


  };


  get_themes() {
    return this.themes;
  }

  set_themes(themes: any) {
    this.themes = themes;
  }


}
